
const initState = {
  viewportSize: 'large'
}
export default (state = initState, action) => {
  switch (action.type) {
    case 'CHANGE_VIEWPORT_SIZE':
      return { ...action }
    default:
      return state
  }
}