import provider from "./src/store/provider"
import moment from 'moment'

import "./src/styles/vendor/bootstrap/bootstrap.scss"
import "./src/styles/styles.scss"
import "react-notifications/lib/notifications.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const wrapRootElement = provider;

export const onClientEntry = () => {
  if (typeof localStorage !== 'undefined') {

    const CAMPAIGN_NAME = 'fabercastell';
    const status = localStorage.getItem('internal_ad');
    
    let popupTrigger = false;

    if (status) {
      let steps = JSON.parse(status)[CAMPAIGN_NAME];
      
      if (!steps[1] || !steps[3]) {
        const _now = moment();
        const initDate = moment(steps.initDate.date);

        if (!steps[1] && _now.diff(initDate, 'days') > 1) {
          popupTrigger = true;
          steps = { ...steps, '1': { date: _now } };
        }
        if ((steps[1] && !steps[3]) && _now.diff(initDate, 'days') > 3) {
          popupTrigger = true;
          steps = { ...steps, '3': { date: _now } };
        }

        localStorage.setItem('internal_ad', JSON.stringify({ [CAMPAIGN_NAME]: steps }));
      }
    } else {
      const store = {
        [CAMPAIGN_NAME]: {
          initDate: {
            date: moment()
          }
        }
      }
      localStorage.setItem('internal_ad', JSON.stringify(store));
      popupTrigger = true;
    }

    if (typeof window !== 'undefined') {
      window.__popupTrigger = popupTrigger;
    }
  }
}

export const onRouteUpdate = (api) => {
  if (typeof window !== 'undefined') {
    if (window.__gdpr) {
      window.__gdpr = false;
      document.location.reload(true);
    }
  }
}
