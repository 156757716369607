
const initState = {
  modalLanding: false
}
export default (state = initState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL_LANDING':
      return { ...action }
    default:
      return state
  }
}