import { createStore, combineReducers } from 'redux';
// import cartReducer from './reducers/cart';
import menuReducer from './reducers/menu';
import homepageReducer from './reducers/homepage';
import modalReducer from './reducers/modal';
import modalLandingReducer from './reducers/modalLanding';
import viewportReducer from './reducers/responsive';

export default () => {
  const store = createStore(
    combineReducers({ 
      // cart: cartReducer, 
      menu: menuReducer, 
      modal: modalReducer,
      modalcta: modalReducer,
      modalBanner: modalReducer,
      modalLanding: modalLandingReducer,
      homepage: homepageReducer,
      viewport: viewportReducer
    })
  );

  store.dispatch({
    type: 'SET_ENV',
    env: process.env.NODE_ENV,
    twicpics: process.env.TWICPICS_ENDPOINT
  });
  return store;
};
