
const initState = {
  homepage: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_HOMEPAGE':
      return { homepage: action.homepage }
    default:
      return state
  }
}