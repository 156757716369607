
const initState = {
  subMenuActive: false,
  subMenuOpened: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_SUBMENU':
      return { ...state, subMenuActive: action.subMenuActive }
    case 'SET_SUBMENU_OPENED':
      return { ...state, subMenuOpened: action.subMenuOpened }
    default:
      return state
  }
}